* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  scroll-behavior: smooth;
}

body {
  background-color: #ECEDFF;
  font-family: Montserrat;
  position: relative;

  &.modal_opened {
    overflow: hidden;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

/* Calendar */
.react-calendar__tile:disabled {
  color: rgb(221, 233, 239) !important;
}
