@font-face {
  font-family: Panton;
  font-weight: 400;
  font-style: normal;
  src: url('./Panton/Regular/Panton-Regular.ttf') format('ttf'),
  url('./Panton/Regular/Panton-Regular.woff2') format('woff2'),
  url('./Panton/Regular/Panton-Regular.woff') format('woff');
}

@font-face {
  font-family: Panton;
  font-weight: 600;
  font-style: normal;
  src: url('./Panton/SemiBold/Panton-SemiBold.ttf') format('ttf'),
  url('./Panton/SemiBold/Panton-SemiBold.woff2') format('woff2'),
  url('./Panton/SemiBold/Panton-SemiBold.woff') format('woff');
}

@font-face {
  font-family: Panton;
  font-weight: 700;
  font-style: normal;
  src: url('./Panton/Bold/Panton-Bold.ttf') format('ttf'),
  url('./Panton/Bold/Panton-Bold.woff2') format('woff2'),
  url('./Panton/Bold/Panton-Bold.woff') format('woff');
}

@font-face {
  font-family: Panton;
  font-weight: 800;
  font-style: normal;
  src: url('./Panton/ExtraBold/Panton-ExtraBold.ttf') format('ttf'),
  url('./Panton/ExtraBold/Panton-ExtraBold.woff2') format('woff2'),
  url('./Panton/ExtraBold/Panton-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url('./Montserrat/Medium/Montserrat-Medium.ttf') format('ttf'),
  url('./Montserrat/Medium/Montserrat-Medium.woff2') format('woff2'),
  url('./Montserrat/Medium/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  src: url('./Montserrat/SemiBold/Montserrat-SemiBold.ttf') format('ttf'),
  url('./Montserrat/SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
  url('./Montserrat/SemiBold/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: NotoSans;
  font-weight: 400;
  font-style: normal;
  src: url('./NotoSans/Regular/NotoSans-Regular.ttf') format('ttf'),
  url('./NotoSans/Regular/NotoSans-Regular.woff2') format('woff2'),
  url('./NotoSans/Regular/NotoSans-Regular.woff') format('woff');
}

@font-face {
  font-family: NotoSans;
  font-weight: 500;
  font-style: normal;
  src: url('./NotoSans/Medium/NotoSans-Medium.ttf') format('ttf'),
  url('./NotoSans/Medium/NotoSans-Medium.woff2') format('woff2'),
  url('./NotoSans/Medium/NotoSans-Medium.woff') format('woff');
}

@font-face {
  font-family: NotoSans;
  font-weight: 700;
  font-style: normal;
  src: url('./NotoSans/Bold/NotoSans-Bold.ttf') format('ttf'),
  url('./NotoSans/Bold/NotoSans-Bold.woff2') format('woff2'),
  url('./NotoSans/Bold/NotoSans-Bold.woff') format('woff');
}